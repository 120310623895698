import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import Configuration from "../helpers/configuration/Configuration"
import Protobuf from "../helpers/configuration/Protobuf"
import { base64url_decode } from "../helpers-shared/base64Url"

const UseDecodeCustomParameter = () => {
  const params = useParams()
  const [values, setValues] = useState(null)
  const [valid, setValid] = useState(false)
  const [loaded, setLoaded] = useState(false)

  // decode protobuf string in order to generate the values object
  useEffect(() => {
    if (!params.SCHEMA || (!params.CCO && !params.CCR)) {
      return
    }

    const schema = params.SCHEMA
    const mode = params.CCO ? 'CCO': 'CCR'
    const string = base64url_decode(params.CCO ? params.CCO : params.CCR)

    new Protobuf(schema).decode(string).then(params => {
      const configuration = new Configuration()
        .setParams(params)
        .setMode(mode)

      setValues(configuration.getValues())
      setValid(configuration.isValid())
      setLoaded(true)
    })
  }, [params])

  return { loaded, values, valid }
}

export default UseDecodeCustomParameter
