import {
  getParedAcabado,
  getParedSideVariableId,
  hasDoorOnSide,
  isGlassAmbienceWall,
  isGlazedWall,
  isInox,
  isNeocompactColourWall,
  isNeocompactTextureWall,
  isNeocompactWoodWall,
  needsCenefa,
  needsPanelMandoCenefa,
} from "../configuration/utils.js"

const getReflejoType = (side, values) => {
  const paredAcabado = getParedAcabado(side, values)

  if (isNeocompactWoodWall(paredAcabado)) {
    return "NCW"
  }

  if (isGlassAmbienceWall(paredAcabado)) {
    return "PG"
  }

  if (isNeocompactColourWall(paredAcabado)) {
    return "NCC"
  }

  if (isNeocompactTextureWall(paredAcabado)) {
    return "NCW"
  }

  if (isInox(paredAcabado) && paredAcabado === "ST11") {
    return "ST01"
  }

  if (isInox(paredAcabado)) {
    return paredAcabado
  }

  if (isGlazedWall(paredAcabado)) {
    return "CRISTAL"
  }

  return ""
}

const getReflejoLayer = (side, values, config = {}) => {
  const { isReflejo = false } = config

  if (!needsReflejoLayer(side, values, config)) {
    return null
  }

  const techoModelo = values.CCAB_TECHO_MODELO
  const reflejoType = getReflejoType(side, values)
  const espejoPared = values.CCAB_ESPEJO_PARED
  const espejoModelo = values.CCAB_ESPEJO_MODELO

  if (!reflejoType) {
    return null
  }

  let imageName = `${side}_${reflejoType}_${techoModelo}`
  if (espejoPared.includes(side) && espejoModelo) {
    imageName = `${imageName}_${espejoModelo}`
  }

  if (isReflejo) {
    imageName = `${imageName}_REFLEJO`
  }

  return {
    folder: "REFLEJOS",
    imageName: imageName,
  }
}

// No se han hecho capas de reflejo de iluminación para todos los casos.
const needsReflejoLayer = (side, values, config = {}) => {
  const { isReflejo = false } = config

  const paredAcabado = getParedAcabado(side, values)

  // El reflejo de la pared frontal no tiene capa
  if (isReflejo && side === "FRONTAL") {
    return false
  }

  // Reflejos de paredes con espejo no tiene
  if (isReflejo && values.CCAB_ESPEJO_PARED.includes(side)) {
    return false
  }

  // izda o dcha
  // con espejo
  // no es inox st15 ni PG
  // --> no tiene

  if (
    side !== "FRONTAL" &&
    values.CCAB_ESPEJO_PARED.includes(side) &&
    paredAcabado !== "ST15" &&
    !isGlassAmbienceWall(paredAcabado)
  ) {
    return false
  }

  return true
}

const getParedAcabadoImage = (side, values) => {
  const paredAcabado = getParedAcabado(side, values)

  if (!paredAcabado) {
    return ""
  }

  const espejoPared = values.CCAB_ESPEJO_PARED
  const espejoModelo = values.CCAB_ESPEJO_MODELO

  let acabado = `${side}_${paredAcabado}`

  if (espejoPared.includes(side) && espejoModelo) {
    acabado = `${acabado}_${espejoModelo}`
  }

  return acabado
}

const getCenefaColor = material => {
  if (!material) {
    return `INOX`
  }

  if (material === "ST11") {
    return `BLACK`
  }

  if (material === "ST15") {
    return material
  }

  // ST01, ST03, ST04, ST06, ST07, ST09 (todos los inox grises)

  return `INOX`
}

const getPanelMandoCenefaColor = paredAcabado => {
  if (!paredAcabado || !isGlazedWall(paredAcabado)) {
    return ""
  }

  return paredAcabado.replace("CRISTAL_", "")
}

const getParedAcabadoLayer = (side, values, config = {}) => {
  const { isReflejo = false } = config

  let paredAcabadoImage = getParedAcabadoImage(side, values)

  if (!paredAcabadoImage) {
    return null
  }

  if (isReflejo) {
    paredAcabadoImage = `${paredAcabadoImage}_REFLEJO`
  }

  return {
    folder: "PAREDES",
    imageName: paredAcabadoImage,
    withPlaceholder: true,
  }
}

const getCenefaLayer = (side, values, config = {}) => {
  const { isReflejo = false } = config

  const cenefaColor = getCenefaColor(values.CCAB_CENEFA_MATERIAL)
  const espejoPared = values.CCAB_ESPEJO_PARED
  const espejoModelo = values.CCAB_ESPEJO_MODELO

  let imageName = `${side}_${cenefaColor}`
  if (espejoPared.includes(side) && espejoModelo === "MT_COMPLETO_ESTRECHO") {
    imageName = `${imageName}_${espejoModelo}`
  }

  if (isReflejo) {
    imageName = `${imageName}_REFLEJO`
  }

  return {
    folder: "PASAMANO_CENEFA",
    imageName: imageName,
  }
}

const getPasamanosLayer = (side, values, config = {}) => {
  const { isReflejo = false } = config

  let imageName = `${side}_${values.CCAB_PASAMANO_MODELO}`

  if (
    values.CCAB_PASAMANO_POSICION.includes(side) &&
    values.CCAB_PANEL_MANDO_POSICION.includes(side)
  ) {
    imageName = `${imageName}_PARTIDO`
  }

  if (isReflejo) {
    imageName = `${imageName}_REFLEJO`
  }

  return {
    folder: "PASAMANO_MODELO",
    imageName: imageName,
  }
}

const getEspejoLayer = (side, values, config = {}) => {
  const { isReflejo = false } = config

  const espejoModelo = values.CCAB_ESPEJO_MODELO

  if (!espejoModelo) {
    return null
  }

  let imageName = `${side}_${espejoModelo}`

  const paredAcabado = getParedAcabado(side, values)

  if (isGlassAmbienceWall(paredAcabado)) {
    return null
  }

  if (isReflejo) {
    imageName = `${imageName}_REFLEJO`
  }

  return {
    folder: "ESPEJOS",
    imageName: imageName,
  }
}

const getEspejoAcabadoLayer = (side, values) => {
  const espejoAcabado = values.CCAB_ESPEJO_ACABADO

  if (!espejoAcabado || espejoAcabado === "ESP_01") {
    // None or "Blanco"
    return null
  }

  return {
    folder: "ESPEJOS",
    imageName: `${side}_${espejoAcabado}`,
  }
}

const getPanelMandoMaterialLayer = (side, values, config = {}) => {
  const { isReflejo = false } = config

  let imageName = `${side}_${values.CCAB_PANEL_MANDO_MATERIAL}`
  if (isReflejo) {
    imageName = `${imageName}_REFLEJO`
  }

  return {
    folder: "PANEL_MANDO_MATERIAL",
    imageName: imageName,
  }
}

const getPanelMandoCabinaSenalLayer = (side, values, config = {}) => {
  const { isReflejo = false } = config

  let imageName = `${side}_${values.CCAB_PANEL_MANDO_CABINA_SENAL}`
  if (isReflejo) {
    imageName = `${imageName}_REFLEJO`
  }

  return {
    folder: "PANEL_MANDO_CABINA_SENAL",
    imageName: imageName,
  }
}

const getPanelMandoCabinaPulsadorLayer = (side, values, config = {}) => {
  const { isReflejo = false } = config

  let imageName = `${side}_${values.CCAB_PANEL_MANDO_SENAL_CABINA_PULSADOR}`
  if (isReflejo) {
    imageName = `${imageName}_REFLEJO`
  }

  return {
    folder: "PANEL_MANDO_SENAL_CABINA_PULSADOR",
    imageName: imageName,
  }
}

const getPanelMandoCabinaPulsadorContrasteLayer = (side, config = {}) => {
  const { isReflejo = false } = config

  let imageName = `${side}_CONTRASTE`
  if (isReflejo) {
    imageName = `${imageName}_REFLEJO`
  }

  return {
    folder: "PANEL_MANDO_SENAL_CABINA_PULSADOR_CONTRASTE",
    imageName: imageName,
  }
}

const getPanelMandoCenefaLayer = (side, values, config = {}) => {
  const { isReflejo = false } = config

  const paredAcabado = getParedAcabado(side, values)

  const cenefaColor = getPanelMandoCenefaColor(paredAcabado)

  let imageName = `${side}_${cenefaColor}`
  if (isReflejo) {
    imageName = `${imageName}_REFLEJO`
  }

  return {
    folder: "PANEL_MANDO_CENEFA",
    imageName: imageName,
  }
}

const getTechoMaterialLayer = values => {
  return {
    folder: "TECHO_MATERIAL",
    imageName: values.CCAB_TECHO_MATERIAL,
  }
}

const getTechoModeloLayer = (values, config = {}) => {
  const { isReflejo = false } = config

  let imageName = values.CCAB_TECHO_MODELO
  if (isReflejo) {
    imageName = `${imageName}_REFLEJO`
  }

  return {
    folder: "TECHO_MODELO",
    imageName: imageName,
  }
}

const getSueloMaterialLayer = values => {
  return {
    folder: "SUELO_MATERIAL",
    imageName: values.CCAB_SUELO_MATERIAL,
  }
}

const getSueloZocalosLayer = values => {
  return {
    folder: "SUELO_ZOCALOS",
    imageName: `${values.CCAB_SUELO_ZOCALOS}`,
  }
}

const getPuertaLayer = (side, values, config = {}) => {
  const { isReflejo = false } = config
  let materialPuerta = values.CCAB_PUERTA_MATERIAL;
  if(materialPuerta === "SH03") {
    materialPuerta = "ST01";
  }
  let imageName = `${side}_${values.CCAB_PUERTA_MODELO}_${materialPuerta}`
  if (isReflejo) {
    imageName = `${imageName}_REFLEJO`
  }

  return {
    folder: "PUERTAS",
    imageName: imageName,
    withPlaceholder: true,
  }
}

const getProteccionesMaterialLayer = (side, values, config = {}) => {
  const { isReflejo = false } = config

  let imageName = `${side}_${values.CCAB_PROTECCIONES_MATERIAL}`

  if (values.CCAB_PANEL_MANDO_POSICION.includes(side)) {
    imageName = `${imageName}_PARTIDO`
  }

  if (isReflejo) {
    imageName = `${imageName}_REFLEJO`
  }

  return {
    folder: "PROTECCIONES_MATERIAL",
    imageName: imageName,
  }
}

const getProteccionesModeloLayer = (side, values, config = {}) => {
  const { isReflejo = false } = config

  let imageName = `${side}_${values.CCAB_PROTECCIONES_MODELO}`

  if (values.CCAB_PANEL_MANDO_POSICION.includes(side)) {
    imageName = `${imageName}_PARTIDO`
  }

  if (isReflejo) {
    imageName = `${imageName}_REFLEJO`
  }

  return {
    folder: "PROTECCIONES_MODELO",
    imageName: imageName,
  }
}

const getSideLayers = (side, values) => {
  const paredSideVariableId = getParedSideVariableId(side)

  let layers = []

  // Espejo acabado

  if (values.CCAB_ESPEJO_PARED.includes(side) && values.CCAB_ESPEJO_ACABADO) {
    layers.push(getEspejoAcabadoLayer(side, values))
  }

  // Espejo opacidad en laterales

  if (side !== "FRONTAL") {
    if (values.CCAB_ESPEJO_PARED.includes(side) && values.CCAB_ESPEJO_ACABADO) {
      layers.push(getOpacidadEspejoLayer(side))
    }
  }

  // Pared acabado
  layers.push(getParedAcabadoLayer(side, values))

  // Pared reflejo
  layers.push(getReflejoLayer(side, values))

  if (side !== "FRONTAL") {
    if (values.CCAB_ESPEJO_PARED.includes(side) && values.CCAB_ESPEJO_MODELO) {
      layers.push(getEspejoLayer(side, values, { isReflejo: true }))
    }
  }

  // Pared reflejo del reflejo
  if (side !== "FRONTAL") {
    layers.push(getReflejoLayer(side, values, { isReflejo: true }))
  }

  // Reflejos (Cenefa, pasamanos y cenefa de panel de mando), excepto frontal
  // La capa para frontal se añade en otro punto posterior
  if (side !== "FRONTAL") {
    if (needsPanelMandoCenefa(side, values)) {
      layers.push(getPanelMandoCenefaLayer(side, values, { isReflejo: true }))
    }

    if (needsCenefa(side, values)) {
      layers.push(getCenefaLayer(side, values, { isReflejo: true }))
    }
  }

  // Reflejo Puertas

  if (side !== "FRONTAL") {
    if (hasDoorOnSide(side, values)) {
      layers.push(getPuertaLayer(side, values, { isReflejo: true }))
    }
  }

  // Espejo

  if (values.CCAB_ESPEJO_PARED.includes(side) && values.CCAB_ESPEJO_MODELO) {
    layers.push(getEspejoLayer(side, values))
  }

  // Cenefa

  if (needsCenefa(side, values)) {
    layers.push(getCenefaLayer(side, values))
  }

  // Cenefa panel mando

  if (needsPanelMandoCenefa(side, values)) {
    layers.push(getPanelMandoCenefaLayer(side, values))
  }

  // Panel de mando

  if (values.CCAB_PANEL_MANDO_POSICION.includes(side)) {
    layers.push(getPanelMandoMaterialLayer(side, values))

    layers.push(getPanelMandoCabinaSenalLayer(side, values))

    layers.push(getPanelMandoCabinaPulsadorLayer(side, values))

    if (values.CCAB_PANEL_MANDO_CONTRASTE) {
      layers.push(getPanelMandoCabinaPulsadorContrasteLayer(side))
    }
  }

  // Reflejos Panel de mando

  if (side !== "FRONTAL") {
    if (values.CCAB_PANEL_MANDO_POSICION.includes(side)) {
      layers.push(getPanelMandoMaterialLayer(side, values, { isReflejo: true }))

      layers.push(
        getPanelMandoCabinaSenalLayer(side, values, { isReflejo: true })
      )

      layers.push(
        getPanelMandoCabinaPulsadorLayer(side, values, { isReflejo: true })
      )

      if (values.CCAB_PANEL_MANDO_CONTRASTE) {
        layers.push(
          getPanelMandoCabinaPulsadorContrasteLayer(side, { isReflejo: true })
        )
      }
    }
  }

  if (side !== "FRONTAL") {
    if (
      values.CCAB_PASAMANO_POSICION.includes(side) &&
      values.CCAB_PASAMANO_MODELO
    ) {
      layers.push(getPasamanosLayer(side, values, { isReflejo: true }))
    }
  }

  // REFLEJO bandas de protección
  if (side !== "FRONTAL") {
    if (
      values.CCAB_PROTECCIONES_TIPO === "BANDAS" &&
      values.CCAB_PROTECCIONES_MATERIAL &&
      !values.CCAB_ESPEJO_PARED.includes(side) &&
      !hasDoorOnSide(side, values) &&
      !isGlazedWall(values[paredSideVariableId]) &&
      !isGlassAmbienceWall(values[paredSideVariableId])
    ) {
      layers.push(
        getProteccionesMaterialLayer(side, values, { isReflejo: true })
      )
    }
  }

  // REFLEJO protecciones tipo "múltiples pasamanos"
  if (side !== "FRONTAL") {
    if (
      values.CCAB_PROTECCIONES_TIPO === "MULTIPLES_PASAMANOS" &&
      values.CCAB_PROTECCIONES_MODELO &&
      !values.CCAB_ESPEJO_PARED.includes(side) &&
      !hasDoorOnSide(side, values) &&
      !isGlazedWall(values[paredSideVariableId]) &&
      !isGlassAmbienceWall(values[paredSideVariableId])
    ) {
      layers.push(getProteccionesModeloLayer(side, values, { isReflejo: true }))
    }
  }

  return layers
}

const getSombraEsquinasLayer = () => {
  return {
    folder: "FONDO",
    imageName: "ESQUINAS",
  }
}

const getOpacidadEspejoLayer = side => {
  const imageName = `REFLEJO_${side}`

  return {
    folder: "FONDO",
    imageName: imageName,
  }
}

const getPuertasDecoLayer = () => {
  return {
    folder: "PUERTAS_DECO",
    imageName: "capa-puertas-deco",
    overrideRootFolder: `/`,
  }
}

const prepareLayers = values => {
  const sides = ["IZQUIERDA", "DERECHA", "FRONTAL"]

  let layers = [{ folder: "FONDO", imageName: "FONDO" }]

  // Suelo
  layers.push(getSueloMaterialLayer(values))

  // Zócalos

  layers.push(getSueloZocalosLayer(values))

  // Techo Material

  layers.push(getTechoMaterialLayer(values))

  // Techo Modelo

  layers.push(getTechoModeloLayer(values))
  layers.push(getTechoModeloLayer(values, { isReflejo: true }))

  // Sombra esquinas
  layers.push(getSombraEsquinasLayer())

  // REFLEJOS frontales en los lados
  // La izquierda y la derecha se añaden a la vez que cada lado (prepareSideLayers)

  layers.push(getParedAcabadoLayer("FRONTAL", values, { isReflejo: true }))

  if (hasDoorOnSide("FRONTAL", values)) {
    layers.push(getPuertaLayer("FRONTAL", values, { isReflejo: true }))
  }

  if (
    values.CCAB_ESPEJO_PARED.includes("FRONTAL") &&
    values.CCAB_ESPEJO_MODELO
  ) {
    layers.push(getEspejoLayer("FRONTAL", values, { isReflejo: true }))
  }

  if (needsCenefa("FRONTAL", values)) {
    layers.push(getCenefaLayer("FRONTAL", values, { isReflejo: true }))
  }

  // TODO: Descomentar cuando haya espejos laterales ¿?
  // if (
  //   values.CCAB_PASAMANO_POSICION.includes("FRONTAL") &&
  //   values.CCAB_PASAMANO_MODELO
  // ) {
  //   layers.push(getPasamanosLayer("FRONTAL", values, { isReflejo: true }))
  // }

  // Sides

  sides.forEach(side => {
    const sideLayers = getSideLayers(side, values)

    layers = [...layers, ...sideLayers]
  })

  //  Puerta frontal

  if (hasDoorOnSide("FRONTAL", values)) {
    layers.push(getPuertaLayer("FRONTAL", values))
  }

  // Pasamanos

  sides.forEach(side => {
    if (
      values.CCAB_PASAMANO_POSICION.includes(side) &&
      values.CCAB_PASAMANO_MODELO
    ) {
      layers.push(getPasamanosLayer(side, values))
    }
  })

  // Puertas izquierda, derecha

  if (hasDoorOnSide("IZQUIERDA", values)) {
    layers.push(getPuertaLayer("IZQUIERDA", values))
  }
  if (hasDoorOnSide("DERECHA", values)) {
    layers.push(getPuertaLayer("DERECHA", values))
  }

  // Protecciones tipo "bandas"

  if (
    values.CCAB_PROTECCIONES_TIPO === "BANDAS" &&
    values.CCAB_PROTECCIONES_MATERIAL
  ) {
    sides.forEach(side => {
      const paredSideVariableId = getParedSideVariableId(side)
      
      if (
        (!values.CCAB_ESPEJO_PARED.includes(side) ||
        ['MH_MEDIO_ESPEJO', 'ML_MEDIO_ANCHO'].includes(values.CCAB_ESPEJO_MODELO)) &&
        !isGlazedWall(values[paredSideVariableId]) &&
        !isGlassAmbienceWall(values[paredSideVariableId]) &&
        !hasDoorOnSide(side, values)
      ) {
        layers.push(getProteccionesMaterialLayer(side, values))
      }
    })
  }

  // Protecciones tipo "múltiples pasamanos"

  if (
    values.CCAB_PROTECCIONES_TIPO === "MULTIPLES_PASAMANOS" &&
    values.CCAB_PROTECCIONES_MODELO
  ) {
    sides.forEach(side => {
      const paredSideVariableId = getParedSideVariableId(side)

      if (
        (!values.CCAB_ESPEJO_PARED.includes(side) ||
        ['MH_MEDIO_ESPEJO', 'ML_MEDIO_ANCHO'].includes(values.CCAB_ESPEJO_MODELO)) &&
        !isGlazedWall(values[paredSideVariableId]) &&
        !isGlassAmbienceWall(values[paredSideVariableId]) &&
        !hasDoorOnSide(side, values)
      ) {
        layers.push(getProteccionesModeloLayer(side, values))
      }
    })
  }

  // Puertas decorativas

  layers.push(getPuertasDecoLayer())

  return layers
}

export {
  getReflejoType,
  getReflejoLayer,
  getParedAcabadoImage,
  getCenefaColor,
  getParedAcabadoLayer,
  getCenefaLayer,
  getPasamanosLayer,
  getEspejoLayer,
  getEspejoAcabadoLayer,
  getPanelMandoMaterialLayer,
  getPanelMandoCabinaPulsadorLayer,
  getTechoMaterialLayer,
  getTechoModeloLayer,
  getSueloMaterialLayer,
  getSueloZocalosLayer,
  getPuertaLayer,
  getProteccionesMaterialLayer,
  getSideLayers,
  getPuertasDecoLayer,
  prepareLayers,
}
