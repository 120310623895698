const isNeocompactColourWall = valueId => {
  return valueId.startsWith("NCC_")
}

const isNeocompactWoodWall = valueId => {
  return valueId.startsWith("NCW_")
}

const isNeocompactTextureWall = valueId => {
  return valueId.startsWith("NCT_")
}

const isGlassAmbienceWall = valueId => {
  return valueId.startsWith("PG_")
}

const isGlazedWall = valueId => {
  return valueId.startsWith("CRISTAL_")
}

const isInox = valueId => {
  return valueId.startsWith("ST")
}

const hasAnyGlazedWall = values => {
  return [
    values.CCAB_PARED_IZDA_ACABADO,
    values.CCAB_PARED_DCHA_ACABADO,
    values.CCAB_PARED_FRONT_ACABADO,
  ].some(acabado => isGlazedWall(acabado))
}

const hasAnyGlassAmbienceWall = values => {
  return [
    values.CCAB_PARED_IZDA_ACABADO,
    values.CCAB_PARED_DCHA_ACABADO,
    values.CCAB_PARED_FRONT_ACABADO,
  ].some(acabado => isGlassAmbienceWall(acabado))
}

const hasAllGlazedWalls = values => {
  const allWalls = allWallsByBoarding(values)

  return allWalls.every(side => {
    const acabado = getParedAcabado(side, values)

    return isGlazedWall(acabado)
  })
}

/**
 * A list of available walls by boarding type
 *
 * @param values
 * @returns {string[]}
 */
const allWallsByBoarding = values => {
  const sides = ["IZQUIERDA", "DERECHA", "FRONTAL"]

  switch (values.CCAB_EMBARQUE) {
    case "270":
      return sides.filter(s => s !== "IZQUIERDA")

    case "90":
      return sides.filter(s => s !== "DERECHA")

    case "180":
      return sides.filter(s => s !== "FRONTAL")

    default:
      // 0
      return sides
  }
}

const canHaveParedAcristalada = values => {
  if (
    values.CCAB_ESPEJO_PARED?.length > 0 ||
    ["PAMA_HDR13", "PAMA_HDR14"].includes(values.CCAB_PASAMANO_MODELO)
  ) {
    return false
  }

  return true
}

const canHaveParedGlassAmbience = values => {
  if (
    values.CCAB_ESPEJO_PARED?.length > 0 &&
    ["MT_COMPLETO_ESTRECHO", "MW_COMPLETO_ANCHO"].includes(
      values.CCAB_ESPEJO_MODELO
    )
  ) {
    return false
  }

  return true
}

const canHaveEspejoModelo = (espejoModelId, values) => {
  if (values.CCAB_ESPEJO_PARED?.length > 0) {
    // Check if any of the mirrored walls is of type glass ambience

    const sidesHaveGlassAmbienceWall = values.CCAB_ESPEJO_PARED.some(side => {
      const acabado = getParedAcabado(side, values)

      return isGlassAmbienceWall(acabado)
    })

    if (
      sidesHaveGlassAmbienceWall &&
      ["MT_COMPLETO_ESTRECHO", "MW_COMPLETO_ANCHO", "MH_MEDIO_ESPEJO"].includes(espejoModelId)
    ) {
      return false
    }

    // Check if any of the mirrored walls has a handrail

    const sidesWithMirrorAndHandrail = values.CCAB_PASAMANO_POSICION.filter(
      side => values.CCAB_ESPEJO_PARED.includes(side)
    )

    if (
      sidesWithMirrorAndHandrail.length > 0 &&
      espejoModelId === "MW_COMPLETO_ANCHO"
    ) {
      return false
    }
  }

  return true
}

const getParedSideVariableId = side => {
  let paredAcabado
  switch (side) {
    case "IZQUIERDA":
      paredAcabado = "CCAB_PARED_IZDA_ACABADO"
      break
    case "DERECHA":
      paredAcabado = "CCAB_PARED_DCHA_ACABADO"
      break
    case "FRONTAL":
      paredAcabado = "CCAB_PARED_FRONT_ACABADO"
      break
    default:
      paredAcabado = ""
  }

  return paredAcabado
}

const getParedAcabado = (side, values) => {
  let paredAcabado
  switch (side) {
    case "IZQUIERDA":
      paredAcabado = values.CCAB_PARED_IZDA_ACABADO
      break
    case "DERECHA":
      paredAcabado = values.CCAB_PARED_DCHA_ACABADO
      break
    case "FRONTAL":
      paredAcabado = values.CCAB_PARED_FRONT_ACABADO
      break
    default:
      paredAcabado = ""
  }

  return paredAcabado
}

const needsCenefa = (side, values) => {
  const paredAcabado = getParedAcabado(side, values)

  if (!paredAcabado) {
    return false
  }

  const espejoPared = values.CCAB_ESPEJO_PARED
  const espejoModelo = values.CCAB_ESPEJO_MODELO

  if (espejoPared.includes(side) && espejoModelo === "MW_COMPLETO_ANCHO") {
    return false
  }

  return isGlassAmbienceWall(paredAcabado)
}

const needsPanelMandoCenefa = (side, values) => {
  const paredAcabado = getParedAcabado(side, values)

  if (!paredAcabado) {
    return false
  }

  const panelMandoPared = values.CCAB_PANEL_MANDO_POSICION

  if (!panelMandoPared.includes(side)) {
    return false
  }

  return isGlazedWall(paredAcabado)
}

const hasDoorOnSide = (side, values) => {
  switch (side) {
    case "IZQUIERDA":
      return values.CCAB_EMBARQUE === "270"

    case "DERECHA":
      return values.CCAB_EMBARQUE === "90"

    case "FRONTAL":
      return values.CCAB_EMBARQUE === "180"

    default:
      return false
  }
}

export {
  isNeocompactColourWall,
  isNeocompactWoodWall,
  isNeocompactTextureWall,
  isGlassAmbienceWall,
  hasAnyGlassAmbienceWall,
  isGlazedWall,
  hasAnyGlazedWall,
  hasAllGlazedWalls,
  allWallsByBoarding,
  canHaveParedAcristalada,
  canHaveParedGlassAmbience,
  getParedAcabado,
  needsCenefa,
  needsPanelMandoCenefa,
  isInox,
  canHaveEspejoModelo,
  getParedSideVariableId,
  hasDoorOnSide,
}
