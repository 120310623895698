const initialValues = {
  // GENERALES
  CCAB_MODELO: "1100_1400",
  CCAB_EMBARQUE: "",
  CCAB_AMBIENTE: "",
  CCAB_ACCESIBILIDAD: "0",
  // PAREDES
  CCAB_PARED_IZDA_ACABADO: "",
  CCAB_PARED_FRONT_ACABADO: "",
  CCAB_PARED_DCHA_ACABADO: "",
  // TECHO
  CCAB_TECHO_MODELO: "",
  CCAB_TECHO_MATERIAL: "",
  // SUELO
  CCAB_SUELO_MATERIAL: "",
  CCAB_SUELO_ZOCALOS: "",
  // ESPEJO
  CCAB_ESPEJO_PARED: "",
  CCAB_ESPEJO_ACABADO: "",
  CCAB_ESPEJO_MODELO: "",
  // PUERTA
  CCAB_MARCO_ANCHO: "",
  CCAB_PUERTA_MODELO: "",
  CCAB_PUERTA_MATERIAL: "",
  CCAB_GRAN_MIRILLA: "",
  // PANELES DE MANDO
  CCAB_PANEL_MANDO_CUANTOS: "",
  CCAB_PANEL_MANDO_POSICION: "",
  CCAB_PANEL_MANDO_MATERIAL: "",
  CCAB_PANEL_MANDO_CABINA_SENAL: "",
  CCAB_PANEL_MANDO_SENAL_CABINA_PULSADOR: "",
  CCAB_PANEL_MANDO_CONTRASTE: "",
  // PASAMANO
  CCAB_PASAMANO_POSICION: "",
  CCAB_PASAMANO_MODELO: "",
  // CENEFA
  CCAB_CENEFA_MATERIAL: "",
  // PROTECCIONES
  CCAB_PROTECCIONES_TIPO: "",
  CCAB_PROTECCIONES_MODELO: "",
  CCAB_PROTECCIONES_MATERIAL: "",
  // EXTERIOR BOTONERA PISO
  CCAB_BOTONERA_POSICION: "",
  CCAB_BOTONERA_PARED: "",
  CCAB_BOTONERA_MARCO: "",
  CCAB_BOTONERA_PULSADOR: "",
  CCAB_BOTONERA_CONTRASTE: "",
  // EXTERIOR SEÑALIZACION DE PISO
  CCAB_SENAL_PISO: "",
  CCAB_SENAL_PISO_UBICACION: "",
  CCAB_SENAL_PISO_TIPO: "",
  CCAB_SENAL_PISO_MARCO_NEGRO: "",
}

export default initialValues
