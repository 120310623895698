const getParedMaterialLayer = values => {
  return {
    folder: "BOTONERA_PARED",
    imageName: values.CCAB_BOTONERA_PARED,
  }
}

const getPuertasMaterialLayer = values => {
  let imageName = `${values.CCAB_PUERTA_MODELO}_${values.CCAB_PUERTA_MATERIAL}`

  if (values.CCAB_MARCO_ANCHO) {
    imageName = `${imageName}_MARCO_ANCHO`
  }

  if(values.CCAB_GRAN_MIRILLA) {
    imageName = `${imageName}_GRAN_MIRILLA`
  }

  return {
    folder: "PUERTAS",
    imageName: imageName,
  }
}

const getSenalPisoTipoLayer = values => {
  if (!values.CCAB_SENAL_PISO) {
    return null
  }

  let imageName = `${values.CCAB_SENAL_PISO_UBICACION}_${values.CCAB_SENAL_PISO_TIPO}`

  if (values.CCAB_SENAL_PISO_TIPO === "SIETE_S_PISO_NEXT" && values.CCAB_SENAL_PISO_MARCO_NEGRO) {
    imageName = `${imageName}_BLACK_FRAME`
  }

  if (
    ((values.CCAB_PUERTA_MODELO === "C2H" &&
      values.CCAB_SENAL_PISO_UBICACION === "PUERTA") ||
      values.CCAB_PUERTA_MODELO === "T2H") &&
      values.CCAB_MARCO_ANCHO
  ) {
    imageName = `${imageName}_${values.CCAB_PUERTA_MODELO}_MARCO_ANCHO`
  }

  return {
    folder: "SENAL_PISO_TIPO",
    imageName: imageName,
  }
}

const getBotoneraMarcoLayer = values => {
  let imageName = `${values.CCAB_BOTONERA_POSICION}_${values.CCAB_BOTONERA_MARCO}`

  if (
    ((values.CCAB_PUERTA_MODELO === "C2H" &&
      values.CCAB_BOTONERA_POSICION === "PARED") ||
      values.CCAB_PUERTA_MODELO === "T2H") &&
    values.CCAB_MARCO_ANCHO
  ) {
    imageName = `${imageName}_${values.CCAB_PUERTA_MODELO}_MARCO_ANCHO`
  }

  return {
    folder: "BOTONERA_MARCO",
    imageName: imageName,
  }
}

const getBotoneraPulsadorLayer = values => {
  let imageName = `${values.CCAB_BOTONERA_POSICION}_${values.CCAB_BOTONERA_PULSADOR}`

  if (
    ((values.CCAB_PUERTA_MODELO === "C2H" &&
      values.CCAB_BOTONERA_POSICION === "PARED") ||
      values.CCAB_PUERTA_MODELO === "T2H") &&
      values.CCAB_MARCO_ANCHO
  ) {
    imageName = `${imageName}_${values.CCAB_PUERTA_MODELO}_MARCO_ANCHO`
  }

  return {
    folder: "BOTONERA_PULSADOR",
    imageName: imageName,
  }
}

const getBotoneraPulsadorContrasteLayer = values => {
  let imageName = `${values.CCAB_BOTONERA_POSICION}_${values.CCAB_BOTONERA_PULSADOR}`

  if (
    ((values.CCAB_PUERTA_MODELO === "C2H" &&
      values.CCAB_BOTONERA_POSICION === "PARED") ||
      values.CCAB_PUERTA_MODELO === "T2H") &&
      values.CCAB_MARCO_ANCHO
  ) {
    imageName = `${imageName}_${values.CCAB_PUERTA_MODELO}_MARCO_ANCHO`
  }

  return {
    folder: "BOTONERA_PULSADOR_CONTRASTE",
    imageName: `${imageName}_CONTRASTE`,
  }
}

const prepareLayers = values => {
  // Fondo
  const layers = [{ folder: "FONDO", imageName: "FONDO" }]

  // Pared
  layers.push(getParedMaterialLayer(values))

  // Puertas
  layers.push(getPuertasMaterialLayer(values))

  // Señal piso tipo
  layers.push(getSenalPisoTipoLayer(values))

  // Botonera marco (???)
  layers.push(getBotoneraMarcoLayer(values))

  // Botonera pulsador
  layers.push(getBotoneraPulsadorLayer(values))

  // Botonera contraste
  if (values.CCAB_BOTONERA_CONTRASTE) {
    layers.push(getBotoneraPulsadorContrasteLayer(values))
  }

  return layers
}

export { prepareLayers }
